<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <brand-update :id="id" @gotAttrs="assignAttrs"></brand-update>
    <product-model-index
      :filtering="filtering"
      :ams_template="ams_template"
    ></product-model-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import BrandUpdate from './BrandUpdate.vue'
import ProductModelIndex from './ProductModelIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    BrandUpdate,
    ProductModelIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        brand_ids: this.id
      },
      ams_template: {
        type: 'product-models',
        description: undefined,
        attributes: {
          'brand-id': this.id
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description
    }
  }
}
</script>